































































import {Component, Vue, Watch} from "vue-property-decorator";
import anime from "animejs/lib/anime.es.js";
import Project from "@/components/Project.vue";

export interface ProjectMeta {
  name: string;
  complete: boolean;
  description: string;
  features: Feature[];
}

export interface Feature {
  name: string;
  complete: boolean;
  bug: boolean;
}

@Component({
  components: {
    Project
  }
})
export default class About extends Vue {
  private selected: number = null;
  private previousSelected: number = null;
  private previousProject: ProjectMeta | null = null;
  private showCard = false;
  private animation;
  private versionNumber = process.env.VUE_APP_VERSION;
  private projectItems: ProjectMeta[] = [
    {
      name: "Home Page",
      complete: true,
      description:
          "Get the front screen set with the old website look for nostalgic reasons",
      features: [
        { name: "Add more details/animations to the projects in the About pages", complete: true, bug: false },
        { name: "Animate Splash Page", complete: false, bug: false }
      ]
    },
    {
      name: "Minesweeper Heatmap",
      complete: true,
      description:
        "A tool that, when you input the current state of a minesweeper board, it will calculate the likely hood of each unclicked squares being bombs.",
      features: [
        { name: "Change cell selection to a universal swapping strategy", complete: false, bug: false },
        { name: "Add instructions and explaination screen", complete: false, bug: false },
        { name: "Fix x overflow issue", complete: false, bug: true },
        { name: "Design mobile perspective", complete: false, bug: false }
      ]
    },
    {
      name: "Sunshine Tracker",
      complete: false,
      description:
          "This is a simple manual collectibles tracker for the game Super Mario Sunshine to assist in fully complete the game.",
      features: []
    },
    {
      name: "Nonogram Solver",
      complete: false,
      description:
          "A tool that, when you input the current state of a nonogram board, it will solve the board.",
      features: []
    },
    {
      name: "Hearthstone Statistics",
      complete: false,
      description:
          "A tool that gives you statistics based on the current state of hearthstone",
      features: []
    },
    {
      name: "Hearthstone Deck Randomizer",
      complete: false,
      description:
          'A tool that will generate a random "okayish" deck that you can import into hearthstone.',
      features: []
    }
  ];

  mounted() {
    if (!this.$vuetify.breakpoint.xs) {
      anime.set((this.$refs.list as Vue).$el, {width: "200%"});
    }
  }

  get shouldShowCard() {
    if (this.$vuetify.breakpoint.xs) {
      return this.showCard;
    }
    return this.showCard && this.animation != null && this.animation.completed;
  }

  @Watch("selected")
  listenToDetailClick() {
    const target = (this.$refs.list as Vue);
    if (this.selected != null) {
      if (
          this.previousProject == null ||
          this.previousProject.name == this.projectItems[this.selected].name
      ) {
        if (this.$vuetify.breakpoint.xs) {
          this.showCard = true;
          this.previousProject = this.projectItems[this.selected];
        } else {
          const ani = anime({
            targets: target.$el,
            width: "100%",
            easing: "easeInOutBack",
            duration: 1000
          });
          ani.finished.then(() => {
            this.showCard = true;
            this.previousProject = this.projectItems[this.selected];
          });
          this.animation = ani;

        }
      } else if (this.showCard) {
        const card = ((this.$refs.card as Vue).$el as HTMLElement)
        anime
            .timeline({
              targets: card
            })
            .add({
              translateY: 100,
              opacity: 0,
              easing: "easeInOutBack",
              duration: 500,
              complete: () => {
                this.previousProject = this.projectItems[this.selected];
              }
            })
            .add({
              translateY: 0,
              opacity: 1,
              easing: "easeInOutBack",
              duration: 500
            })
            .finished.then(() => {
          card.style.transform = "";
          card.style.opacity = "";
        });
      }
      anime({
        targets: (this.$refs.icon as Vue[])[this.selected].$el,
        rotate: 540,
        easing: "easeInOutBack",
        duration: 1000
      });
    } else {
      this.showCard = false;
      if (!this.$vuetify.breakpoint.xs) {
        const ani  = anime({
          targets: target.$el,
          width: "200%",
          easing: "easeInOutBack",
          duration: 1000
        });
        ani.finished.then(() => {
          this.showCard = false;
          this.previousProject = null;
        });
        this.animation = ani;
      }
    }
    if (this.previousSelected != null) {
      anime({
        targets: (this.$refs.icon as Vue[])[this.previousSelected].$el,
        rotate: 0,
        easing: "easeInOutBack",
        duration: 1000
      });
    }
    this.previousSelected = this.selected;
  }
}
