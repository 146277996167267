


































import {Component, Prop, Vue} from "vue-property-decorator";
import {ProjectMeta} from "@/views/About.vue";
import ProjectIcon from "@/components/ProjectIcon.vue";

@Component({
  components: {
    ProjectIcon
  }
})
export default class Project extends Vue {
  @Prop() private previousProject: ProjectMeta;

  get name() {
    return this.previousProject != null ? this.previousProject.name : "";
  }

  get description() {
    return this.previousProject != null ? this.previousProject.description : "";
  }

  get complete() {
    return this.previousProject != null ? this.previousProject.complete : false;
  }

  get features() {
    return this.previousProject != null ? this.previousProject.features : [];
  }
}
