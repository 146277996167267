






















































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class App extends Vue {
  private drawer = false;
  private drawerItems = [
    { name: "Home", path: "/", icon: "mdi-home" },
    { name: "Minesweeper Heatmap", path: "/minesweeper", icon: "mdi-mine" },
    { name: "About", path: "/about", icon: "mdi-progress-wrench" }
  ];

  callMoving(payload: boolean) {
    this.$store.dispatch("moving", payload);
  }

  autoCollapse() {
    this.drawer = false;
  }
}
